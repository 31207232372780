import * as React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { useSentry } from "@heyhabito/sentry"

import {
  Card,
  GridRow,
  HeadlineLarge,
  breakpoints,
  colours,
  column,
  gridSettings,
  horizontal,
  media,
  mkColumn,
  mkSubGridRow,
  PlusLogo,
  PrimaryLink,
  SecondaryLink,
  randomExpertImageLinks,
  Tag,
  typographyStyles,
  useMediaQuery,
  vertical,
  ResponsiveImage,
} from "design-kit"

import flower_person_webp from "../../views/Homepage/assets/flower_person.webp"
import flower_person_png from "../../views/Homepage/assets/flower_person.png"
import flower_person_retina_webp from "../../views/Homepage/assets/flower_person_retina.webp"
import flower_person_retina_png from "../../views/Homepage/assets/flower_person_retina.png"
import house_big_webp from "../../views/Homepage/assets/house_blue.webp"
import house_big_png from "../../views/Homepage/assets/house_blue.png"
import house_big_retina_webp from "../../views/Homepage/assets/house_blue_retina.webp"
import house_big_retina_png from "../../views/Homepage/assets/house_blue_retina.png"

import cloud_pink_1_webp from "../../views/Homepage/HowItWorks/assets/cloud_pink_1.webp"
import cloud_pink_1_png from "../../views/Homepage/HowItWorks/assets/cloud_pink_1.png"
import cloud_pink_2_webp from "../../views/Homepage/HowItWorks/assets/cloud_pink_2.webp"
import cloud_pink_2_png from "../../views/Homepage/HowItWorks/assets/cloud_pink_2.png"
import key_webp from "../../views/Homepage/HowItWorks/assets/key-trippy.webp"
import key_png from "../../views/Homepage/HowItWorks/assets/key-trippy.png"
import house_webp from "../../views/Homepage/HowItWorks/assets/house.webp"
import house_png from "../../views/Homepage/HowItWorks/assets/house.png"
import house_retina_webp from "../../views/Homepage/HowItWorks/assets/house_retina.webp"
import house_retina_png from "../../views/Homepage/HowItWorks/assets/house_retina.png"
import sun_webp from "../../views/Homepage/HowItWorks/assets/sun.webp"
import sun_png from "../../views/Homepage/HowItWorks/assets/sun.png"
import sun_retina_webp from "../../views/Homepage/HowItWorks/assets/sun_retina.webp"
import sun_retina_png from "../../views/Homepage/HowItWorks/assets/sun_retina.png"

const Section = styled.section`
  background-color: #fface5;
  background-image: linear-gradient(
    #98d9ff 0%,
    #ffc1f9 32%,
    #fface5 61%,
    #fff691 100%
  );
  overflow: hidden;
  position: relative;
`

const Cloud1 = styled.img`
  display: none;

  ${breakpoints.desktop`
    display: block;
    position: absolute;
    top: 100px;
    right: -40px;
  `}
`
const Cloud2 = styled.img`
  display: none;

  ${breakpoints.desktop`
    display: block;
    position: absolute;
    top: 500px;
    left: -40px;
  `}
`
const Cloud3 = styled.img`
  display: none;

  ${breakpoints.desktop`
    display: block;
    position: absolute;
    bottom: 500px;
    right: 40px;
  `}
`

interface HouseProps {
  width: string
  left?: string
  right?: string
  isFlipped?: boolean
  isMobile?: boolean
}

const HouseImg = styled.img<HouseProps>`
  ${props =>
    props.isMobile
      ? `bottom: 0;
    display: initial;
    position: absolute;
    width: ${props.width};
    ${
      props.right
        ? `right: ${props.right};`
        : props.left
          ? `left: ${props.left};`
          : ""
    }

    ${breakpoints.desktop`
      display: none;
    `}
    `
      : `display: none;`}

  ${props =>
    !props.isMobile &&
    breakpoints.desktop`
    bottom: 0;
    display: initial;
    position: absolute;
    width: ${props.width};
    ${
      props.right
        ? `right: ${props.right};`
        : props.left
          ? `left: ${props.left};`
          : ""
    }
    ${props.isFlipped ? `transform: scaleX(-1) translateY(1px);` : ""}
  `}
`

const Key = styled.img`
  display: none;

  ${breakpoints.desktop`
    display: initial;
    position: absolute;
    right: -325px;
    top: -24px;
    width: 400px;
  `}
`

const subGridSettings = {
  desktop: {
    ...gridSettings.desktop,
    maxColumns: 9,
  },
  tablet: gridSettings.tablet,
  mobile: gridSettings.mobile,
}

const SubGridRow = mkSubGridRow(breakpoints)(subGridSettings)
const subColumn = mkColumn(breakpoints)(subGridSettings)

const LeftAlignedCard = styled(Card)`
  margin-bottom: ${vertical.l};

  ${breakpoints.desktop`
      margin-bottom: ${vertical.xxl};
    `}

  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 9,
  })}
`

const RightAlignedCard = styled(Card)`
  margin-bottom: ${vertical.l};

  ${breakpoints.desktop`
    margin-bottom: ${vertical.xxl};
  `}

  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 9,
    offsetDesktop: 3,
  })}
`

const ItemTitle = styled.p`
  ${typographyStyles.headline1};
  margin-bottom: ${vertical.xs};

  ${breakpoints.desktop`
    margin-right: ${horizontal.l};
    margin-bottom: ${vertical.s};
  `}
`

const ItemSubtitle = styled.p`
  ${typographyStyles.statement4};
  margin-bottom: ${vertical.xs};

  ${breakpoints.desktop`
    margin-right: ${horizontal.l};
    margin-bottom: ${vertical.s};
  `}
`

const ItemCopy = styled(HeadlineLarge)`
  margin-bottom: ${vertical.s};

  &:last-child {
    margin-bottom: 0;
  }
`

const ItemNumberColumn = styled.div`
  ${subColumn({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 1,
  })}

  display: flex;
  align-items: center;

  ${breakpoints.desktop`
    display: block;
    text-align: center;
  `}
`

const ItemNumber = styled.div`
  ${typographyStyles.headline2};
  background-color: ${colours.offBlack};
  color: ${colours.white};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${vertical.xs};
  width: 40px;
  height: 40px;

  ${breakpoints.desktop`
    margin-bottom: 0;
    width: 64px;
    height: 64px;
  `}

  > span {
    margin-top: -0.3rem;
  }
`

const ItemInnerColumn = styled.div`
  ${subColumn({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 4,
  })}
`

const Sun = (
  <ResponsiveImage
    srcRetinaWebP={sun_retina_webp}
    srcRetina={sun_retina_png}
    srcWebP={sun_webp}
    srcDefault={sun_png}
    loading="lazy"
    imageType="image/png"
    alt=""
    imgCss={css`
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: 120px;
      bottom: -60px;

      animation: pulse 14s linear infinite;

      @keyframes pulse {
        0% {
          transform: scale(1) rotate(0deg);
        }
        10% {
          transform: scale(1.1) rotate(36deg);
        }
        20% {
          transform: scale(1) rotate(72deg);
        }
        30% {
          transform: scale(1.1) rotate(108deg);
        }
        40% {
          transform: scale(1) rotate(144deg);
        }
        50% {
          transform: scale(1.1) rotate(180deg);
        }
        60% {
          transform: scale(1) rotate(216deg);
        }
        70% {
          transform: scale(1.1) rotate(252deg);
        }
        80% {
          transform: scale(1) rotate(288deg);
        }
        90% {
          transform: scale(1.1) rotate(324deg);
        }
        100% {
          transform: scale(1) rotate(360deg);
        }
      }

      ${breakpoints.desktop`
        width: 160px;
        bottom: -80px;
      `}
    `}
  />
)

const House: React.FC<HouseProps & { isBig?: boolean }> = props => {
  return (
    <picture>
      {props.isBig ? (
        <React.Fragment>
          <source
            media={media.retinaScreen.query}
            srcSet={house_big_retina_webp}
            type="image/webp"
          />

          <source
            media={media.retinaScreen.query}
            srcSet={house_big_retina_png}
            type="image/png"
          />

          <source srcSet={house_big_webp} type="image/webp" />

          <HouseImg src={house_big_png} {...props} alt="" loading="lazy" />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <source
            media={media.retinaScreen.query}
            srcSet={house_retina_webp}
            type="image/webp"
          />

          <source
            media={media.retinaScreen.query}
            srcSet={house_retina_png}
            type="image/png"
          />

          <source srcSet={house_webp} type="image/webp" />

          <HouseImg src={house_png} {...props} alt="" loading="lazy" />
        </React.Fragment>
      )}
    </picture>
  )
}

const Neighbourhood = styled.div`
  ${subColumn({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 12,
  })}
`

const ScrollRef = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 12,
  })}
`
interface Props {
  scrollRef: React.RefObject<HTMLDivElement>
}

export const HowItWorks: React.FC<Props> = ({ scrollRef }) => {
  const { logToSentryDetailed } = useSentry()

  const isDesktop = useMediaQuery(breakpoints.desktop.query)

  const [areWeOnline, setAreWeOnline] = React.useState<boolean>(false)

  React.useEffect(() => {
    const getOpeningHours = (): void => {
      fetch(`/api/opening-hours/online-now`, {
        method: "GET",
      })
        .then(res => res.json())
        .then(isOpen => {
          if (typeof isOpen === "boolean") {
            setAreWeOnline(isOpen)
          } else {
            throw `Type of /online-now fetch res was ${typeof isOpen} instead of boolean.`
          }
        })
        .catch(err =>
          logToSentryDetailed({
            description: `Could not fetch online-now from opening hours: ${err.message} `,
            info: { cause: err, location, identifier: "Homepage" },
            tags: { specific_error: "opening hours: online-now" },
          })
        )
    }

    const FIVE_MINUTES = 1000 * 60 * 5
    const interval = setInterval(getOpeningHours, FIVE_MINUTES)

    getOpeningHours()

    return () => clearInterval(interval)
  }, [])

  const randomExperts = React.useMemo(() => randomExpertImageLinks(3), [])

  return (
    <Section>
      {isDesktop && (
        <React.Fragment>
          <picture>
            <source srcSet={cloud_pink_1_webp} type="image/webp" />
            <Cloud1 src={cloud_pink_1_png} alt="" />
          </picture>

          <picture>
            <source srcSet={cloud_pink_1_webp} type="image/webp" />
            <Cloud2 src={cloud_pink_1_png} alt="" loading="lazy" />
          </picture>

          <picture>
            <source srcSet={cloud_pink_2_webp} type="image/webp" />
            <Cloud3 src={cloud_pink_2_png} alt="" loading="lazy" />
          </picture>
        </React.Fragment>
      )}

      <GridRow>
        <ScrollRef
          css={css`
            padding-top: ${vertical.xl};
          `}
          ref={scrollRef}
          tabIndex={-1}
        />

        <LeftAlignedCard element="div" kind="semi-transparent">
          <SubGridRow>
            <ItemNumberColumn>
              <ItemNumber>
                <span>1</span>
              </ItemNumber>
            </ItemNumberColumn>

            <ItemInnerColumn>
              <ItemTitle>We find your perfect mortgage</ItemTitle>
              <ItemSubtitle>
                out of 20,000 options from 90+ banks & lenders
              </ItemSubtitle>
            </ItemInnerColumn>
            <ItemInnerColumn>
              <ItemCopy>
                Tell us about you and the home you’re looking to buy, and we’ll
                set you up with your own mortgage expert.
              </ItemCopy>
              <ItemCopy>
                We’re a whole-of-market broker, so we have access to nearly
                every mortgage out there. Hell, we have access to exclusive
                deals from your own bank that your own bank won’t offer you.
              </ItemCopy>
            </ItemInnerColumn>
          </SubGridRow>
        </LeftAlignedCard>

        <RightAlignedCard element="div" kind="semi-transparent">
          <SubGridRow>
            <ItemNumberColumn>
              <ItemNumber>
                <span>2</span>
              </ItemNumber>
            </ItemNumberColumn>

            <ItemInnerColumn>
              <ItemTitle>Your team of experts</ItemTitle>
              <ItemSubtitle>do all the applying & following up</ItemSubtitle>
              {randomExperts.map((expert, index) => (
                <img
                  key={`ME-${index}`}
                  src={expert}
                  alt="Our friendly mortgage expert"
                  loading="lazy"
                  css={css`
                    width: 64px;
                    margin-right: ${horizontal.xs};
                    :last-of-type {
                      margin-right: ${horizontal.m};
                    }
                  `}
                />
              ))}
              {areWeOnline && (
                <Tag
                  kind="positive"
                  text="Online now"
                  css={css`
                    margin-top: ${vertical.xs};
                    ${breakpoints.desktop`
                      margin: auto 0;
                    `}
                  `}
                />
              )}
            </ItemInnerColumn>
            <ItemInnerColumn>
              <ItemCopy>
                We do the application paperwork for you, then pester the bank
                for updates until your keys are in hand.
              </ItemCopy>
              <ItemCopy>
                Your mortgage expert and case manager stay by your side the
                whole way, keeping you in the loop.
              </ItemCopy>
            </ItemInnerColumn>
          </SubGridRow>
          {isDesktop && (
            <ResponsiveImage
              srcRetinaWebP={flower_person_retina_webp}
              srcRetina={flower_person_retina_png}
              srcWebP={flower_person_webp}
              srcDefault={flower_person_png}
              loading="lazy"
              imageType="image/png"
              alt=""
              imgCss={css`
                display: none;

                ${breakpoints.desktop`
                    display: initial;
                    left: -332px;
                    position: absolute;
                    top: calc(50% - 20px);
                    transform: translateY(-50%);
                    width: 238px;
                  `}
              `}
            />
          )}
        </RightAlignedCard>

        <LeftAlignedCard element="div" kind="semi-transparent">
          <SubGridRow>
            <ItemNumberColumn>
              <ItemNumber>
                <span>3</span>
              </ItemNumber>
            </ItemNumberColumn>

            <ItemInnerColumn>
              <div
                css={css`
                  margin-bottom: ${vertical.xs};
                `}
              >
                <PlusLogo height={36} />
              </div>
              <ItemTitle>
                We do the legal work, sort your property survey
              </ItemTitle>
              <ItemSubtitle>& guide you through the results</ItemSubtitle>
            </ItemInnerColumn>
            <ItemInnerColumn>
              <ItemCopy>
                Here’s who you’ll have on side: A surveyor, who gets you a
                condition report; a solicitor, who handles all the legal stuff;
                and your case manager, who supports you the whole way.
              </ItemCopy>
              <ItemCopy>
                We’ll handle all the legal stuff and get you a comprehensive
                report on your future home, so you know exactly what you’re
                buying – and it’s synced up with your mortgage for serious
                efficiency.
              </ItemCopy>

              <SecondaryLink
                text="More about Plus"
                href={"/home-buying"}
                descriptiveText="More about Plus"
                width="content-width"
              />
            </ItemInnerColumn>

            {isDesktop && (
              <picture>
                <source srcSet={key_webp} type="image/webp" />

                <Key alt="" src={key_png} loading="lazy" />
              </picture>
            )}
          </SubGridRow>
        </LeftAlignedCard>

        <p
          css={css`
            ${typographyStyles.statement1}
            text-align: center;
            ${column({
              widthMobile: 4,
              widthTablet: 6,
              widthDesktop: 12,
            })};
            margin-bottom: ${vertical.s};

            ${breakpoints.tablet`
              margin-bottom: ${vertical.m};
            `}
          `}
        >
          Life’s too short{" "}
          <span
            css={css`
              ${breakpoints.desktop`
            display: block;
            `}
            `}
          >
            for life admin
          </span>
        </p>

        <PrimaryLink
          text="Let’s do this"
          href="/sign-up"
          descriptiveText="Let’s do this: sign up"
          width="content-width"
          css={css`
            margin: 0 auto 180px;
          `}
        />

        <Neighbourhood aria-label="Imagine this... the sun is rising, birds are chirping, and you start your day safe and content in your new home after a smooth Habito journey.">
          <House isMobile={true} width="120px" left="-40px" />

          <House width="243px" isBig={true} isFlipped={true} left="-40px" />
          <House width="160px" left="calc(203px + 8px)" />
          <House
            width="83px"
            left="calc(203px + 8px + 160px + 8px)"
            isFlipped={true}
          />

          {Sun}

          <House isMobile={true} isBig={true} width="160px" right="-80px" />

          <House width="100px" right="calc(223px + 8px + 127px + 8px)" />
          <House width="127px" right="calc(223px + 8px)" />
          <House width="243px" isBig={true} right="-20px" />
        </Neighbourhood>
      </GridRow>
    </Section>
  )
}
