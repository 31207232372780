import {
  breakpoints,
  PrimaryLink,
  typographyStyles,
  useHasMounted,
  useMediaQuery,
  vertical,
} from "design-kit"
import React from "react"
import { css } from "@emotion/react"

import config from "../../config.json"
import Footer from "../shared-components/Footer"
import Navbar from "../shared-components/Navbar"
import { HomepageBase } from "../views/Homepage/Base"
import { WrappedHead } from "../shared-components/Shared/WrappedBase"
import { Hero } from "../views/Homepage/Hero"
import { HowItWorks } from "./Partner/HowItWorks"
import { Reviews } from "../shared-components/Reviews"

import emmas_diary_svg from "./Partner/assets/emmas_diary.svg"
import money_saving_expert_svg from "./Partner/assets/money_saving_expert.svg"
import money_to_the_masses_png from "./Partner/assets/money_to_the_masses.png"
import nuts_about_money_svg from "./Partner/assets/nuts_about_money.svg"
import property_investment_project_png from "./Partner/assets/property_investment_project.png"
import four_walls_svg from "./Partner/assets/4walls.svg"
import pie_png from "./Partner/assets/pie.png"
import nriwallah_png from "./Partner/assets/nriwallah.png"

import type partners from "./partners.json"

type PartnerName = keyof typeof partners

const logos: Record<PartnerName, string> = {
  emmasdiary: emmas_diary_svg,
  moneysavingexpert: money_saving_expert_svg,
  moneytothemasses: money_to_the_masses_png,
  nutsaboutmoney: nuts_about_money_svg,
  propertyinvestmentproject: property_investment_project_png,
  fourwalls: four_walls_svg,
  pie: pie_png,
  nriwallah: nriwallah_png,
}

export interface PageContext {
  name: PartnerName
  displayName: string
  canonical: string
}

export const Head: React.FC<{
  pageContext: PageContext
}> = ({ pageContext }) => {
  const { name, displayName, canonical } = pageContext
  const pageName = `partnership_homepage_${name}`
  const title = `Habito: Welcome from ${displayName}`

  return (
    <WrappedHead
      metaTitle={title}
      metaDescription="We’re the Treating Customers Fairly Champion of 2023 as voted for in the British Bank Awards. We’re here to make mortgages easier - no paperwork, no stress and no fees."
      canonicalUrl={canonical}
      noIndex={true}
      pageName={pageName}
      intercom={true}
      config={config}
    />
  )
}

const CardContent: React.FC<PageContext> = ({ name, displayName }) => {
  const title = "We’ll get you the best mortgage"
  const logo = logos[name]
  return (
    <>
      <h1 css={css(typographyStyles.statement2)}>{title}</h1>
      <p
        css={css`
          ${typographyStyles.headlineLarge}

          margin: ${vertical.xs} auto ${vertical.s};
          max-width: 440px;

          ${breakpoints.desktop`
            max-width: unset;
           `}
        `}
      >
        All for free, all in one peaceful place.
        <div
          css={css`
            margin-top: ${vertical.s};
          `}
        >
          {logo && (
            <img
              src={logo}
              alt={name}
              css={css`
                margin: 0 auto ${vertical.xs};
                max-width: 80%;
                max-height: 150px;
                width: auto;
                height: auto;
              `}
            />
          )}
        </div>
        Welcome from{" "}
        <strong
          css={css`
            white-space: nowrap;
          `}
        >
          {displayName}
        </strong>
        . <br />
        Let’s get the best mortgage for you.
      </p>
      <PrimaryLink
        text="Get started"
        href="/sign-up"
        width={{
          mobile: "full-width",
          tablet: "content-width",
          desktop: "content-width",
        }}
      />
    </>
  )
}

const Homepage: React.FC<{ pageContext: PageContext }> = ({ pageContext }) => {
  const scrollRef = React.useRef<HTMLDivElement>(null)

  const hasMounted = useHasMounted()

  const isDesktop = useMediaQuery(breakpoints.desktop.query)

  return (
    <HomepageBase>
      <Navbar
        key={`${hasMounted}`}
        theme={isDesktop ? "opaque" : "transparent-dark"}
      />

      <main id="main-content">
        <Hero
          ctaCopy={"How it works"}
          scrollRef={scrollRef}
          cardContent={<CardContent {...pageContext} />}
        />

        <HowItWorks scrollRef={scrollRef} />

        <Reviews />
      </main>

      <Footer />
    </HomepageBase>
  )
}

export default Homepage
